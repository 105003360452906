const production = true;
const local = !production;

/* Prototype 	: cGUID
* Purpose 		: Generates a Global Unique ID
* Return		  :	Return GUID
* History   	: Created on 2015-02-06
*
**/
const cGUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

  /***
 * Prototype 	: 	cTimeStamp
 * Purpose 		: 	return TimeStamp as "AAAA-MM-JJ HHMMSS" format
 * History		: 	Created on 2014-08-06
 *
 **/
const cTimeStamp = () => {
    let lDate = new Date();
    let l_sTimeStamp = '';

    l_sTimeStamp = 1900 + lDate.getYear();
    l_sTimeStamp += '-';
    l_sTimeStamp += ('0' + (lDate.getMonth() + 1)).slice(-2);
    l_sTimeStamp += '-';
    l_sTimeStamp += ('0' + lDate.getDate()).slice(-2);
    l_sTimeStamp += ' ';

    l_sTimeStamp += ('0' + lDate.getHours()).slice(-2);
    l_sTimeStamp += ('0' + lDate.getMinutes()).slice(-2);
    l_sTimeStamp += ('0' + lDate.getSeconds()).slice(-2);

    return (l_sTimeStamp);
}

const globals = {
    parameters : {
            debugging : false, 
            chatbot_enabled:true,
            sticky3_debug:false,
            festival_enabled:false,
            assistant_helper_collapsed:true,
            local:local,
            top_actu:false
        },
    menu : {
            donate : "Soutenir la mission",
            visit_us : "Visiter la croisade de la prière",
            daily_worship : "Méditation du jour",
            /*booklets: "Fascicules",*/
            about_us:"A propos de nous...",
            subscribe:"Adhérer",
            participation:"Festival de la prière",
            bible_read:"Lire la Bible"
    },
    daily_worship_endpoint : {
        ...(local && {url : 'http://localhost:4008/worship'}),
        ...(production && {url : 'https://daily-service.jesusestlareponsepourtous.org/worship'})
    },
    req_stats_endpoint : {
        ...(local && {url : 'http://localhost:4006/req-stats2'}),
        ...(production && {url : 'https://survey-service.jesusestlareponsepourtous.org/req-stats2'})
    },
    file_list_endpoint : {
        ...(local && {url : 'http://localhost:4009/file-list'}),
        ...(production && {url : 'https://media-service.jesusestlareponsepourtous.org/file-list'})
    },
    subscriber_add_endpoint : {
        ...(local && {url : 'http://localhost:5100/subscriber-add'}),
        ...(production && {url : 'https://mailer.jesusestlareponsepourtous.org/subscriber-add'})
    },
    subscriber_acknowledge_endpoint : {
        ...(local && {url : 'http://localhost:5100/subscriber-acknowledge'}),
        ...(production && {url : 'https://mailer.jesusestlareponsepourtous.org/subscriber-acknowledge'})
    },
     subscriber_mail_broadcast_endpoint : {
        ...(local && {url : 'http://localhost:5100/subscriber-mail-broadcast'}),
        ...(production && {url : 'https://mailer.jesusestlareponsepourtous.org/subscriber-mail-broadcast'})
    },
    participant_add_endpoint : {
        ...(local && {url : 'http://localhost:5100/participant-add'}),
        ...(production && {url : 'https://mailer.jesusestlareponsepourtous.org/participant-add'})
    },
    participant_acknowledge_endpoint : {
        ...(local && {url : 'http://localhost:5100/participant-acknowledge'}),
        ...(production && {url : 'https://mailer.jesusestlareponsepourtous.org/participant-acknowledge'})
    },
    participant_mail_broadcast_endpoint : {
        ...(local && {url : 'http://localhost:5100/participant-mail-broadcast'}),
        ...(production && {url : 'https://mailer.jesusestlareponsepourtous.org/participant-mail-broadcast'})
    },
    bible_text_endpoint : {
        ...(local && {url : 'http://localhost:5001/bible-text'}),
        ...(production && {url : 'https://bible-service.lacroisadedelapriere.org/bible-text'})
    },
    bible_books_endpoint : {
        ...(local && {url : 'http://localhost:5001/bible-books'}),
        ...(production && {url : 'https://bible-service.lacroisadedelapriere.org/bible-books'})
    },
    bible_chapters_endpoint : {
        ...(local && {url : 'http://localhost:5001/bible-chapters'}),
        ...(production && {url : 'https://bible-service.lacroisadedelapriere.org/bible-chapters'})
    },    
    share:{
        worship:{
            ...(local && {url : 'http://localhost:3000/dailyworship'}),
            ...(production && {url : 'https://jlrpt.com/dailyworship'})
        },
        complot:{
            ...(local && {url : 'http://localhost:3000/'}),
            ...(production && {url : 'https://jlrpt.com/'})
        },

        complot_description : 'Je partage avec toi, une conférence Complotisme ou Vérité du 14 au 21 mai.',
        worship_description : 'Je partage avec toi, la Méditation du jour.'
    },
    daily_worship_start_date : {
        day : 30,
        month : 3,
        year : 2023,
    },
    weekly : {
        ref:"Psaume 34.8",
        verse:"L'ange de l'Eternel campe autour de ceux qui le craignent, et il les arrache au danger."
    }
    ,
    mailsync : {
        ...(local && {url : 'http://localhost:4404'}),
        ...(production && {url : 'https://adv-mailer-service.thononadvent.com'})
    },
    datasync_service : {
        ...(local && {url : "http://localhost:8888/datasync-service"}),
        ...(production && {url : 'https://rest.datasync.ovh'})
    },
    datasync :{
        /*DATASYNC*/
        jlrpt_company_guid:"1FF9E337-39E5-8C25-C43E-D331788B53B9",
        media_table_guid:"3B2DD7AF-1F4C-D2E6-D465-97345F9A0AAA",
        /*jlrpt_company_guid:"3",
        media_table_guid:"3",*/
       
        /*  ASUS local */
        ...(local && {
        syncTestUrl : "http://localhost:8888/datasync-service/test.php",
        syncPushUrl : "http://localhost:8888/datasync-service/Sync.php",
        syncStartUrl: "http://localhost:8888/datasync-service/Sync.php?action=syncStart",
        syncPullUrl : "http://localhost:8888/datasync-service/Sync.php?action=syncPull",
        syncMobilePullUrl : "http://localhost:8888/datasync-service/Sync.php?action=syncMobilePull"}),
        
        
        /* Neuronal.bible Production Server */
        ...(production && {
        syncTestUrl : "https://rest.datasync.ovh/test.php",
        syncPushUrl : "https://rest.datasync.ovh/Sync.php",
        syncStartUrl: "https://rest.datasync.ovh/Sync.php?action=syncStart",
        syncPullUrl : "https://rest.datasync.ovh/Sync.php?action=syncPull",
        syncMobilePullUrl : "https://rest.datasync.ovh/Sync.php?action=syncMobilePull"}),
        /**/
    }
}

  //------------------ Simulate click event
  const onClickAssistantHelper = e =>{
    var element = document.querySelector(".rsc-float-button");
    //this.setState({chatbot_help_collapsed:true})
    if (element)
      element.click();
  }


export {globals, onClickAssistantHelper, cGUID, cTimeStamp}